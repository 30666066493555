import React, { Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import MainLayout from "./components/layout/MainLayout";
import "./index.css";

// Lazy load komponenty
const CaseStudies = React.lazy(() =>
  import("./components/page/CaseStudies/CaseStudies")
);
const Blog = React.lazy(() =>
  import("./components/page/BlogStandard/BlogStandard")
);
const PrivacyPolicy = React.lazy(() =>
  import("./components/page/PrivacyPolicy")
);
const Oddsee = React.lazy(() => import("./components/page/CaseStudies/Oddsee"));
const Wgmi = React.lazy(() => import("./components/page/CaseStudies/Wgmi"));
const Mthrbrd = React.lazy(() =>
  import("./components/page/CaseStudies/mthrbrd")
);
const Metaverse = React.lazy(() =>
  import("./components/page/CaseStudies/Metaverse")
);
const Billon = React.lazy(() => import("./components/page/CaseStudies/Billon"));
const PageNotFound = React.lazy(() =>
  import("./components/page/error/PageNotFound")
);
const BlogDetails = React.lazy(() =>
  import("./components/page/BlogDetails/BlogDetails")
);
const ProductPassport = React.lazy(() =>
  import("./components/page/CaseStudies/ProductPassport")
);
const BriefForm = React.lazy(() =>
  import("./components/page/brief-form/BriefForm")
);
const FilipPuzyr = React.lazy(() =>
  import("./components/page/CaseStudies/FilipPuzyr")
);
const AboutUs = React.lazy(() => import("./components/page/about-us/AboutUs"));
const Career = React.lazy(() => import("./components/page/career/Career"));
const ContactPage = React.lazy(() =>
  import("./components/page/contact/ContactPage")
);
const Palto = React.lazy(() => import("./components/page/CaseStudies/Palto"));
const DredgingOperations = React.lazy(() =>
  import("./components/page/CaseStudies/DredgingOperations")
);
const OversiteWeb = React.lazy(() =>
  import("./components/page/CaseStudies/OversiteWeb")
);
const OversiteMobileApp = React.lazy(() =>
  import("./components/page/CaseStudies/OversiteMobileApp")
);
const Oligamy = React.lazy(() =>
  import("./components/page/CaseStudies/Oligamy")
);
const AllCaseStudies = React.lazy(() =>
  import("./components/page/all-case-studies/AllCaseStudies")
);
const OfferWrapper = React.lazy(() =>
  import("./components/page/offer/OfferWrapper")
);
const LicensesPage = React.lazy(() => import("./components/page/Licenses"));

function App() {
  return (
    <>
      <BrowserRouter basename="/">
        <Suspense fallback={<div>Ładowanie...</div>}>
          <Switch>
            {/*main layout*/}
            <Route exact path="/" component={MainLayout} />
            {/* all inner page load layout component */}
            <Switch>
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/design/oddsee`}
                component={Oddsee}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/design/wgmi`}
                component={Wgmi}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/design/mthrbrd`}
                component={Mthrbrd}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/design`}
                component={CaseStudies}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/design/case-studies`}
                component={AllCaseStudies}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/design/blog`}
                component={Blog}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/design/blog-post/:id`}
                component={BlogDetails}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/design/metaverse`}
                component={Metaverse}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/design/document-reader`}
                component={Billon}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/design/filip-puzyr`}
                component={FilipPuzyr}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/design/palto`}
                component={Palto}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/design/dredging-operations`}
                component={DredgingOperations}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/design/oversite-web`}
                component={OversiteWeb}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/design/oversite-mobile-app`}
                component={OversiteMobileApp}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/design/oligamy`}
                component={Oligamy}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/design/product-passport`}
                component={ProductPassport}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/design/expectations-brief`}
                component={BriefForm}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/privacy-policy`}
                component={PrivacyPolicy}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/licenses`}
                component={LicensesPage}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/about-us`}
                component={AboutUs}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/career`}
                component={Career}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/offer/:slug`}
                component={OfferWrapper}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/contact`}
                component={ContactPage}
              />
              <Route component={PageNotFound} />
            </Switch>
          </Switch>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
