import React, { Suspense } from "react";

// Lazy load komponenty
const HeroArea = React.lazy(() => import("./HeroArea"));
const HumanApproach = React.lazy(() => import("./HumanApproach/HumanApproach"));
const ReadAboutUs = React.lazy(() => import("./ReadAboutUs/ReadAboutUs"));
const CaseStudiesHome = React.lazy(() =>
  import("./CaseStudiesHome/CaseStudiesHome")
);
const InTheNews = React.lazy(() => import("./InTheNews/InTheNews"));
const Scene = React.lazy(() => import("./HomeAnimation/Scene"));

function HomePageOne() {
  return (
    <>
      <div className="homepage">
        <Suspense fallback={<div>Ładowanie...</div>}>
          <HeroArea />
        </Suspense>
        <div className="bg-[#FDFDFD]">
          <Suspense fallback={<div>Ładowanie...</div>}>
            <Scene />
            <HumanApproach />
            <ReadAboutUs />
            <CaseStudiesHome />
            <InTheNews />
          </Suspense>
        </div>
      </div>
    </>
  );
}

export default HomePageOne;
